import AllRoutes from "./Routes/AllRoutes";
import { HelmetProvider } from "react-helmet-async";
import React, { useEffect, useContext } from "react";
import { AuthContext } from "./Context/AuthContextProvider";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loader from "./Components/Loader";

function App() {
  let { showForm, setShowForm } = useContext(AuthContext);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const pageHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    if (scrollPosition >= pageHeight * 0.35) { 
      setShowForm(true);
      window.removeEventListener("scroll", handleScroll);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="App">
        <SkeletonTheme baseColor="#959595" highlightColor="#888">
          <HelmetProvider>
            <Loader />
            <AllRoutes />
          </HelmetProvider>
        </SkeletonTheme>
      </div>
    </>
  );
}

export default App;
