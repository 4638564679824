import styles from "../Styles/Loader.module.css";
import logo from "../Assets/cm-logo-meta-white.png";
import { useEffect, useState } from "react";
import loadingGif from "../Assets/newLoader.gif"; 

let Loader = () => {
  let [showLoader, setShowLoader] = useState(true);
  let [gifLoaded, setGifLoaded] = useState(false);
  let [hideCreativeText, setHideCreativeText] = useState(false);
  useEffect(() => {
    if (gifLoaded) {
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
    }
  }, [gifLoaded]);

  useEffect(() => {
    if (showLoader) {
      setTimeout(() => {
        setHideCreativeText(true);
      }, 500);
    }
  }, [showLoader]);

  return (
    <>
      <img
        className={styles.hideLoader}
        src={loadingGif}
        onLoad={() => {
          setGifLoaded(true);
        }}
        style={{
          display: "none",
          opacity: "0",
          visibility: "hidden",
          position: "absolute",
        }}
      />
      <div
        className={`${styles.loader} ${!showLoader ? styles.hideLoader : null}`}
      >
        <div
          className={`${styles.innerLoaderDiv} ${
            hideCreativeText ? styles.hideInnerLoaderDiv : null
          }`}
        >
          <h3>Creative Monk</h3>
        </div>

        <img className={`${styles.creativeMonkLogoWhiteLoader} ${hideCreativeText ? styles.showCreativeMonkLogoWhiteLoader : null}`} src={logo} />
      </div>
    </>
  );
};

export default Loader;
